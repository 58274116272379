import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Container } from '../components/container'
import { Layout } from '../components/layout'
import { SlideCaption, Slider } from '../components/slider'
import circleCheck from '../assets/images/circle_check.svg'
import chat from '../assets/images/chat.svg'
import { Link } from 'gatsby'
import { hexToRGBA } from '../components/sc-theme'

const IndexPage: FC = () => {
  const { t } = useTranslation('home')

  return (
    <Layout title={t('documentTitle')} activePage="/">
      <Slider interval={5000}>
        <>
          <StaticImage
            src="../assets/images/exclusive-maintenance-slider1.jpg"
            alt=""
            layout="fullWidth"
          />
          <SlideCaption>{t('slider.first')}</SlideCaption>
        </>
        <>
          <StaticImage
            src="../assets/images/exclusive-maintenance-slider2.jpg"
            alt=""
            layout="fullWidth"
          />
          <SlideCaption>{t('slider.second')}</SlideCaption>
        </>
        <>
          <StaticImage
            src="../assets/images/exclusive-maintenance-slider3.jpg"
            alt=""
            layout="fullWidth"
          />
          <SlideCaption>{t('slider.third')}</SlideCaption>
        </>
      </Slider>

      <DescriptionSection>
        <DescriptionContainer>
          <DescriptionContent>{t('description.first')}</DescriptionContent>
          <DescriptionContent highlight>
            {t('description.second')}
          </DescriptionContent>
          <DescriptionContent>{t('description.third')}</DescriptionContent>
        </DescriptionContainer>
      </DescriptionSection>

      <AdvantagesSection>
        <Container>
          <h1>{t('advantages.title')}</h1>
          <AdvantagesList>
            <li>{t('advantages.first')}</li>
            <li>{t('advantages.second')}</li>
            <li>{t('advantages.third')}</li>
            <li>{t('advantages.fourth')}</li>
            <li>{t('advantages.fifth')}</li>
            <li>{t('advantages.sixth')}</li>
            <li>{t('advantages.seventh')}</li>
          </AdvantagesList>
          <ContactFlyout>
            <p>{t('advantages.contactText')}</p>
            <Link to="/contact">
              <div className="icon__chat">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.33334 6.66671V30L11.3333 24C11.9097 23.5661 12.6119 23.332 13.3333 23.3334H26.6667C28.5076 23.3334 30 21.841 30 20V6.66671C30 4.82576 28.5076 3.33337 26.6667 3.33337H6.66667C4.82572 3.33337 3.33334 4.82576 3.33334 6.66671ZM6.66667 23.3334V6.66671H26.6667V20H12.2233C11.5018 19.9981 10.7994 20.2322 10.2233 20.6667L6.66667 23.3334Z"
                    fill="#1A6B93"
                  />
                  <path
                    d="M36.6667 36.6667V15C36.6667 13.1591 35.1743 11.6667 33.3333 11.6667V30L29.7767 27.3334C29.2006 26.8989 28.4982 26.6648 27.7767 26.6667H11.6667C11.6667 28.5077 13.1591 30 15 30H26.6667C27.3881 29.9987 28.0903 30.2328 28.6667 30.6667L36.6667 36.6667Z"
                    fill="#1A6B93"
                  />
                </svg>
              </div>
              {t('advantages.contactUs')}
            </Link>
          </ContactFlyout>
        </Container>
      </AdvantagesSection>
    </Layout>
  )
}

export default IndexPage

const DescriptionSection = styled.section`
  ${({ theme }) => css`
    background-color: ${hexToRGBA(theme.colors.light, 0.1)};
    padding-top: 5.2rem;
    padding-bottom: 5.2rem;
  `}
`
const DescriptionContainer = styled(Container)`
  ${({ theme }) => css`
    ${theme.media.min('tablet_w')} {
      display: grid;
      grid-template-areas:
        'first first second'
        'third third second';
      grid-column-gap: 8.625rem;
      grid-row-gap: 1rem;
    }
  `}
`

interface DescriptionContextProps {
  highlight?: boolean
}
const DescriptionContent = styled.p<DescriptionContextProps>`
  ${({ theme, highlight }) => css`
    ${highlight &&
    css`
      font-family: ${theme.typography.raleway};
      font-size: 1.5rem;
      color: ${theme.colors.brand};
    `}

    ${theme.media.min('tablet_w')} {
      &:first-of-type {
        grid-area: first;
      }

      ${highlight &&
      css`
        grid-area: second;
      `}

      &:last-of-type {
        grid-area: third;
      }
    }
  `}
`

const AdvantagesSection = styled.section`
  ${({ theme }) => css`
    background: ${theme.colors.brand};
    color: ${theme.colors.white};
    margin-bottom: 7.5rem;

    h1 {
      font-family: ${theme.typography.raleway};

      ${theme.media.min('tablet_w')} {
        text-align: center;
      }
    }
  `}
`

const AdvantagesList = styled.ul`
  ${({ theme }) => css`
    padding-top: 1rem;
    padding-bottom: 2.375rem;

    ${theme.media.min('tablet_w')} {
      padding-top: 3.5rem;
      padding-bottom: 4.5rem;
      column-count: 3;
    }

    li {
      background: url(${circleCheck}) no-repeat left top;
      padding-left: 1.875rem;
    }
  `}
`

const ContactFlyout = styled(Container)`
  ${({ theme }) => css`
    position: relative;
    border-radius: 40px;
    background: ${theme.colors.accent};
    color: ${theme.colors.brand};
    margin-bottom: -7.5rem;
    max-width: 42rem;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    ${theme.media.min('tablet_w')} {
      padding: 4rem 4.5rem 4rem 15rem;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -8rem;
        top: -4rem;
        height: 22.2rem;
        width: 22.2rem;
        background-size: 22.2rem 22.2rem;
        background-image: url(${chat});
        background-repeat: no-repeat;
      }
    }

    a {
      font-family: ${theme.typography.montserrat};
      font-size: 1.25rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      padding-top: 2rem;

      .icon__chat {
        height: 2.5rem;
        width: 2.5rem;
        padding-right: 1rem;

        path {
          fill: ${theme.colors.brand};
        }
      }

      &:hover {
        color: ${theme.colors.white};

        svg path {
          fill: ${theme.colors.white};
        }
      }
    }
  `}
`
